import React from "react";
import "./style.css";
import { FaGithub, FaInstagram, FaEnvelope } from "react-icons/fa";
import { socialprofils } from "../../content_option";

export default function Socialicons(params) {
  return (
    <div className="stick_follow_icon">
      <ul>
        <li>
          <a href={socialprofils.email}>
            <FaEnvelope />
          </a>
        </li>
        <li>
          <a href={socialprofils.instagram} target="_blank" rel="noreferrer noopener">
            <FaInstagram />
          </a>
        </li>
        <li>
          <a href={socialprofils.github} target="_blank" rel="noreferrer noopener">
            <FaGithub />
          </a>
        </li>
      </ul>
      <p>Share this</p>
    </div>
  );
}
