import React from 'react';
import './PackageCard.css'; // This is where your styles will be imported from

const servicesIcons = {
  drone: '/path/to/drone-icon.png', // Replace with your icons paths
  // Add other service icons here
};

const PackageCard = ({ image, title, size, services, description, price }) => {
  return (
    <div  className="package-card-top">
    <div className="package-card-div">
      <img src={image} alt={title} className="package-image" />
      <p className="package-size text-left p-2">{size}</p>
      {/* <div className="package-services text-right p-2">
        {services.map(service => (
          <img key={service} src={servicesIcons[service]} alt={service} />
        ))}
      </div> */}
      <h2 className="package-title text-left p-2">{title}</h2>
      <p className="package-description pb-6">{description}</p>
      
    </div>
    <div className="package-price text-right">
     
            <a href="/realestateprices">A PARTIR DE {price}€ →</a>
            {/* <svg class="h-8 w-8 text-white text-right"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
            </svg> */}
    
    </div>
</div>
  );
};

export default PackageCard;
