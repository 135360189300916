import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
  Route,
  Link
} from "react-router-dom";
import AppRoutes from "./routes";
import Headermain from "../header";
// import AnimatedCursor from "react-animated-cursor";
import Socialicons from "../components/socialicons";
import "./App.css";
import { alpha } from '@material-ui/core/styles'


// import Store from "../pages/index/store";
// import { commerce } from '../lib/commerce';
// import Products from "../components/Products/Products";
// import Cart from "../components/Cart/Cart";
// const header = new Headers();
// header.append('X-Authorization', process.env.STRIPE_SECRET_KEY);
//TODO: Verificar como retirar a parte async das funções. Possivel que  com axios
// -H "Authorization: Bearer sk_test_4eC39HqLyjWDarjtT1zdp7dc"
function _ScrollToTop(props) {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

// export default function App() {
 function App() {
  
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState('');
 

  useEffect(()=> {

  }, []);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  // console.log(cart);
  
  return (
    <Router basename={process.env.PUBLIC_URL}>
      {/* <div className="cursor__dot">
        <AnimatedCursor
          innerSize={15}
          outerSize={15}
          color="255, 255 ,255"
          outerAlpha={0.4}
          innerScale={0.7}
          outerScale={5}
        />
      </div> */}
      <ScrollToTop>
        <Headermain handleDrawerToggle={handleDrawerToggle}/>  
        <AppRoutes
        error={errorMessage}
        /> 
        <Socialicons />
       </ScrollToTop>
    </Router> 
  );
}
export default App;
