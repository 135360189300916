import React, { useEffect } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../content_option";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import "../mainPage.css";

const imagesArray = [
  {
    img: "./img/Site/photoshots/airbnb/airbnb23.jpg", alt: "galeria photoshot airbnb 23"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb24.jpg", alt: "galeria photoshot airbnb 24"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb25.jpg", alt: "galeria photoshot airbnb 25"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb26.jpg", alt: "galeria photoshot airbnb 26"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb27.jpg", alt: "galeria photoshot airbnb 27"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb28.jpg", alt: "galeria photoshot airbnb 28"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb29.jpg", alt: "galeria photoshot airbnb 29"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb30.jpg", alt: "galeria photoshot airbnb 30"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb31.jpg", alt: "galeria photoshot airbnb 31"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb32.jpg", alt: "galeria photoshot airbnb 32"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb33.jpg", alt: "galeria photoshot airbnb 33"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb34.jpg", alt: "galeria photoshot airbnb 34"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb35.jpg", alt: "galeria photoshot airbnb 35"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb36.jpg", alt: "galeria photoshot airbnb 36"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb37.jpg", alt: "galeria photoshot airbnb 37"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb38.jpg", alt: "galeria photoshot airbnb 38"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb39.jpg", alt: "galeria photoshot airbnb 39"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb40.jpg", alt: "galeria photoshot airbnb 40"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb41.jpg", alt: "galeria photoshot airbnb 41"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb42.jpg", alt: "galeria photoshot airbnb 42"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb43.jpg", alt: "galeria photoshot airbnb 43"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb44.jpg", alt: "galeria photoshot airbnb 44"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb45.jpg", alt: "galeria photoshot airbnb 45"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb46.jpg", alt: "galeria photoshot airbnb 46"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb47.jpg", alt: "galeria photoshot airbnb 47"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb48.jpg", alt: "galeria photoshot airbnb 48"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb49.jpg", alt: "galeria photoshot airbnb 49"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb50.jpg", alt: "galeria photoshot airbnb 50"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb51.jpg", alt: "galeria photoshot airbnb 51"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb52.jpg", alt: "galeria photoshot airbnb 52"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb53.jpg", alt: "galeria photoshot airbnb 53"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb55.jpg", alt: "galeria photoshot airbnb 55"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb1.jpg", alt: "galeria photoshot airbnb 1"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb2.jpg", alt: "galeria photoshot airbnb 2"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb3.jpg", alt: "galeria photoshot airbnb 3"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb4.jpg", alt: "galeria photoshot airbnb 4"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb5.jpg", alt: "galeria photoshot airbnb 5"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb6.jpg", alt: "galeria photoshot airbnb 6"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb7.jpg", alt: "galeria photoshot airbnb 7"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb8.jpg", alt: "galeria photoshot airbnb 8"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb9.jpg", alt: "galeria photoshot airbnb 9"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb10.jpg", alt: "galeria photoshot airbnb 10"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb11.jpg", alt: "galeria photoshot airbnb 11"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb12.jpg", alt: "galeria photoshot airbnb 12"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb13.jpg", alt: "galeria photoshot airbnb 13"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb14.jpg", alt: "galeria photoshot airbnb 14"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb15.jpg", alt: "galeria photoshot airbnb 15"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb16.jpg", alt: "galeria photoshot airbnb 16"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb17.jpg", alt: "galeria photoshot airbnb 17"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb18.jpg", alt: "galeria photoshot airbnb 18"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb19.jpg", alt: "galeria photoshot airbnb 19"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb20.jpg", alt: "galeria photoshot airbnb 20"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb21.jpg", alt: "galeria photoshot airbnb 21"
  },{
    img: "./img/Site/photoshots/airbnb/airbnb22.jpg", alt: "galeria photoshot airbnb 22"
  }

]

export default function Post2() {
  useEffect(() => {

    if (document.querySelectorAll(".imgs_gallery img") !== undefined) {

      document.querySelector(".imgs_gallery").onmouseover = function (e) {
        for (let i = 0; i < document.querySelectorAll(".imgs_gallery img").length; i++) {
          document.querySelectorAll(".imgs_gallery img")[i].onmouseover = function (e) {

            if (window.innerWidth <= 750) {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.1)';
            } else {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.5)';
            }

            e.target.style.zIndex = '10';

          }
          document.querySelectorAll(".imgs_gallery img")[i].onmouseout = function (e) {

            e.target.style.transform = 'rotate(0) scale(1)';
            e.target.style.zIndex = '1';
            e.target.style.marginLeft = '0em';

          }
        }

      }
    }
  }, []);

  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Night Photoshots | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-2 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Airbnb Photography</h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
          <h3 className="absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>

        </Row>
        <div className="container ab_block_desc mb-6 ">
          <p>For the last year I've been working directly with Airbnb to get the hosts the best showcase of their places. </p>
          <p>And help them transmit the feel of warmth and cozyness they want people to be in their places :)</p>
        </div>
        <div className="container ab_block imgs_gallery ">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry
              gutter={"10px"}
            >
              {imagesArray.map((e, key) =>
                <>
                  {/* <img className="shadow-2xl bg-white p-2 " key={key} src={e.img} alt={e.alt} /> */}
                  <img className="shadow-2xl bg-white gallery_img" key={key} src={e.img} alt={e.alt} style={{width: "100%", display: "block"}} />
                </>
              )}
            </Masonry>
          </ResponsiveMasonry>
        </div>

      </Container>
    </HelmetProvider>
  );
}
