    // PriceTable.js
    import React, {useEffect, useState} from 'react';
    import { Helmet, HelmetProvider } from "react-helmet-async";
    import { Container, Row, Col, Button } from "react-bootstrap";
    import { meta } from "../../content_option";

    import './priceTable.css'; // Ensure to write your styles accordingly
    import './priceTablecss.css';

    const PriceTable = () => {
    // Your table data could be in state, props, or even a static constant
    const pricingData = {
        basePrices: ['140€', '180€', '240€', '300€'],
        // shots: ['10-15', '15-20', '20-30', '30-50'],
        addons: [
        { service: 'Pós-processamento avançado', prices: ['YES', 'YES', 'YES', 'YES'] },
        { service: 'Mapping com Drone', prices: ['+20€', '+20€', '+40€', '+40€'] },
        { service: 'Entrega rápida (24h)', prices: ['+20€', '+30€', '+40€', '+50€'] },
        { service: 'Virtual Tour', prices: ['+30€', '+40€', '+50€', '+60€'] },
        { service: 'Floor Plans', prices: ['+20€', '+25€', '+30€', '+40€'] },
        { service: 'Video', prices: ['+150€', '+200€', '+250€', '+280€'] },
        ],
    };
    
            const [size, setSize] = useState('nothing');
            const [droneMapping, setDroneMapping] = useState(false);
            const [quickTurnaround, setQuickTurnaround] = useState(false);
            const [virtualTour, setVirtualTour] = useState(false);
            const [floorPlan, setFloorPlan] = useState(false);
            const [video, setVideo] = useState(false);
            const [price, setPrice] = useState(0);
            const [finalString, setFinalString] = useState(false);
        
            // Define base prices
            const basePrices = {
            nothing: '',
            small: 100,
            standard: 140,
            large: 200,
            luxury: 260,
            };
        
            // Addon prices
            const addonPrices = {
            drone: 40,
            droneMapping: 20,
            quickTurnaround: { small: 20, standard: 30, large: 40, luxury: 50 },
            virtualTour: { small: 30, standard: 40, large: 50, luxury: 60 },
            floorPlan: { small: 20, standard: 25, large: 30, luxury: 40 },
            video: { small: 150, standard: 200, large: 250, luxury: 280 },
            };
        
            const calculatePrice = () => {
            // Start with the base price
            let calculatedPrice = basePrices[size];
            // if (size===0){
            //     setFinalString("Selecione o tamanho do imóvel.");
            // } 
            
            if (size!='nothing' || size==null) {
            calculatedPrice += addonPrices.drone;
            // Add drone price if selected
            if (droneMapping) {
                calculatedPrice += 20; // Full price without drone
            }
        
            // Add quick turnaround price if selected
            if (quickTurnaround && size) {
                calculatedPrice += addonPrices.quickTurnaround[size];
            }
        
            // Add virtual tour price if selected
            if (virtualTour && size) {
                calculatedPrice += addonPrices.virtualTour[size];
            }
        
            // Add floor plan price if selected
            if (floorPlan && size) {
                calculatedPrice += addonPrices.floorPlan[size];
            }
            if (video && size) {
                calculatedPrice += addonPrices.video[size];
            }
        
            // Update the state with the new price
                setPrice(calculatedPrice);
                // if (calculatePrice && isNaN(calculatedPrice)) {
                //     setFinalString("Selecione o tamanho do apartamento antes dos addons.");
                // }else{
                setFinalString ('O preço para os serviços selecionados :' + calculatedPrice + '€');
                    

                // }
            }else{
                setFinalString("Selecione o tamanho do apartamento antes dos addons.");
            }
            };
        
            // This will be called when the form is submitted
            const handleSubmit = (event) => {
            event.preventDefault(); // Prevent the default form submit action
            calculatePrice();
            };
            // useEffect(() => {
            //     // This function will run whenever one of the dependencies changes.
            //     calculatePrice();
            //   }, [size, droneMapping, quickTurnaround, virtualTour, floorPlan]); // Add all dependencies here
              


    return (
        <>
        <HelmetProvider>
            <Container className="landing-header">
                <Helmet>
                    <meta charSet="utf-8" />
                        <title>Real Estate Photography Prices | {meta.title}</title>
                    <meta name="description" content={meta.description} />
                </Helmet>
                <Row className="mt-2 mr-2 ml-2">
                    <Col>
                        <h1 className="display-4 title_mobile mb-2">Tabela de preços e Serviços</h1>
                        <br/>
                        <div className="boder_important_div">
                            <h4 className="border_important">Pacotes já incluiem fotos aérias e tempo de entrgra máximo de 72h.</h4>
                        </div>
                        
                    </Col>
                </Row>
            </Container>
        </HelmetProvider>
        <Row>
        <Col md={12} sm={12} xs={12} className=''>
            <form onSubmit={handleSubmit}>
      <div>
        <label>
          Escolha o tamanho do imóvel
          <select value={size} defaultValue={0} onChange={(e) => setSize(e.target.value)}>
            <option value="nothing">Selecione...</option>
            <option value="small">Base (≤80m²)</option>
            <option value="standard">Standard (>80m² & ≤140m²)</option>
            <option value="large">Avançado (>140m² & ≤200m²)</option>
            <option value="luxury">Luxo (>200m²)</option>
          </select>
        </label>
      </div>
      <div>
        <label>
            <input
            type="checkbox"
            checked={droneMapping}
            onChange={(e) => setDroneMapping(e.target.checked)}
          />
          Mapping com Drone:
         
        </label>
      </div>
      <div>
        <label>
            <input
            type="checkbox"
            checked={quickTurnaround}
            onChange={(e) => setQuickTurnaround(e.target.checked)}
          />
          Entrega rápida - 24h:
          
        </label>
      </div>
      <div>
        <label>
        <input
            type="checkbox"
            checked={virtualTour}
            onChange={(e) => setVirtualTour(e.target.checked)}
          />
          Virtual Tour:
          
        </label>
      </div>
      <div>
        <label>
        <input
            type="checkbox"
            checked={floorPlan}
            onChange={(e) => setFloorPlan(e.target.checked)}
          />
          Floor Plans:
          
        </label>
      </div><div>
        <label>
        <input
            type="checkbox"
            checked={video}
            onChange={(e) => setVideo(e.target.checked)}
          />
         Video:
          
        </label>
      </div>
      <div>
        <button type="submit">Calcular o preço</button>
      </div>
      {price > 0 && (
        <div>
            {finalString}
          {/* <p>The estimated price for the selected services is: {price}€</p> */}
        </div>
      )}
        </form>
            </Col>
            <Col md={12} sm={12} xs={12}><div className="price-table-container">
        <table className="price-table">
            <thead>
            <tr>
                <th>Service / Package Size</th>
                <th>BASE (&le; 80m2)</th>
                <th>STANDARD (&le; 140m2)</th>
                <th>AVANÇADO (&le; 200m2)</th>
                <th>LUXO (>200m2)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Base price</td>
                {pricingData.basePrices.map((price, index) => (
                <td key={index}>{price}</td>
                ))}
            </tr>
            {pricingData.addons.map((addon, index) => (
                <tr key={index}>
                <td>{addon.service}</td>
                {addon.prices.map((price, index) => (
                    <td key={index}>{price}</td>
                ))}
                </tr>
            ))}
            </tbody>
        </table>
        
        </div>
        </Col>
            
            
        </Row>
        <h3 className="absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>

        
       
        </>
    );

    };

    export default PriceTable;
