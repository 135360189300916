import React, { useEffect } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../content_option";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import "../mainPage.css";

const imagesArray = [
  {
    img: "./img/Site/photoshots/hotel_photography/promar/promar_1.webp", alt: "galeria photoshot promar 1"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar2.webp", alt: "galeria photoshot promar 2"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar3.webp", alt: "galeria photoshot promar 3"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar4.webp", alt: "galeria photoshot promar 4"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar5.webp", alt: "galeria photoshot promar 5"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar6.webp", alt: "galeria photoshot promar 6"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar7.webp", alt: "galeria photoshot promar 7"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar8.webp", alt: "galeria photoshot promar 8"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar9.webp", alt: "galeria photoshot promar 9"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar10.webp", alt: "galeria photoshot promar 10"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar11.webp", alt: "galeria photoshot promar 11"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar12.webp", alt: "galeria photoshot promar 12"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar13.webp", alt: "galeria photoshot promar 13"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar14.webp", alt: "galeria photoshot promar 14"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar15.webp", alt: "galeria photoshot promar 15"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar16.webp", alt: "galeria photoshot promar 16"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar17.webp", alt: "galeria photoshot promar 17"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar18.webp", alt: "galeria photoshot promar 18"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar19.webp", alt: "galeria photoshot promar 19"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar20.webp", alt: "galeria photoshot promar 20"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar21.webp", alt: "galeria photoshot promar 21"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar22.webp", alt: "galeria photoshot promar 22"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar23.webp", alt: "galeria photoshot promar 23"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar24.webp", alt: "galeria photoshot promar 24"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar25.webp", alt: "galeria photoshot promar 25"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar26.webp", alt: "galeria photoshot promar 26"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar27.webp", alt: "galeria photoshot promar 27"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar28.webp", alt: "galeria photoshot promar 28"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar30.webp", alt: "galeria photoshot promar 28"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar31.webp", alt: "galeria photoshot promar 28"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar32.webp", alt: "galeria photoshot promar 28"
  },{
    img: "./img/Site/photoshots/hotel_photography/promar/promar33.webp", alt: "galeria photoshot promar 28"
  }
  


]

export default function Post2() {
  useEffect(() => {

    if (document.querySelectorAll(".imgs_gallery img") !== undefined) {

      document.querySelector(".imgs_gallery").onmouseover = function (e) {
        for (let i = 0; i < document.querySelectorAll(".imgs_gallery img").length; i++) {
          document.querySelectorAll(".imgs_gallery img")[i].onmouseover = function (e) {

            if (window.innerWidth <= 750) {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.1)';
            } else {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.5)';
            }

            e.target.style.zIndex = '10';

          }
          document.querySelectorAll(".imgs_gallery img")[i].onmouseout = function (e) {

            e.target.style.transform = 'rotate(0) scale(1)';
            e.target.style.zIndex = '1';
            e.target.style.marginLeft = '0em';

          }
        }

      }
    }
  }, []);

  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Night Photoshots | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-2 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Hotel Photography > Promar</h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
          <h3 className="absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>

        </Row>
        <div className="container ab_block_desc mb-6 ">
          <p>Hotel Photography is the art of describing visually how confortable and home felling an hotel is.</p>
          <p>This session happened as Promar Hotel wanted photos that characterized the hotel the way it is!</p>
        </div>
        <div className="container ab_block imgs_gallery ">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry
              gutter={"10px"}
            >
              {imagesArray.map((e, key) =>
                <>
                  {/* <img className="shadow-2xl bg-white p-2 " key={key} src={e.img} alt={e.alt} /> */}
                  <img className="shadow-2xl bg-white gallery_img" key={key} src={e.img} alt={e.alt} style={{width: "100%", display: "block"}} />
                </>
              )}
            </Masonry>
          </ResponsiveMasonry>
        </div>

      </Container>
    </HelmetProvider>
  );
}
