import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typical from "react-typical";
import { introdata, meta } from "../../content_option";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick.min.css";
import "./slick.css";
import { useEffect } from "react";

export default function Home() {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 3000,
    autoplay: true,
    variableWidth: true,
    dots: false
  };
  
  return (
   

    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title> {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <div className="intro_sec d-block d-lg-flex align-items-center div_slider_height ">
          <Slider className="h_bg-image order-1 order-lg-2 h-fit slider_height" {...settings}>
            <div><img src="./img/Site/photoshots/self_portrait/self_portrait1.webp" /></div>
            <div><img src="./img/Site/photoshots/engagement/s&a/s&a33.webp" /></div>
            <div><img src="./img/Site/photoshots/engagement/s&a/s&a3.webp" /></div>
            <div><img src="./img/Site/concertCoverage/syro/galeria/galeria_syro_4.webp" /></div>
            <div><img src="./img/Site/concertCoverage/syro/galeria/galeria_syro_6.webp" /></div>
            <div><img src="./img/Site/concertCoverage/syro/galeria/galeria_syro_7.webp" /></div>
          </Slider>
          <div className="text order-2 order-lg-1 h-100 d-lg-flex justify-content-center">
            <div className="align-self-center margin_custom ">
              <div className="intro mx-auto">
                <h2 className="mb-1x">{introdata.title}</h2>
                <h1 className="fluidz-48 mb-1x">
                  <Typical
                    steps={[
                      introdata.animated.first,
                      5000,
                      introdata.animated.second,
                      5000,
                      // introdata.animated.third,
                      // 5000
                    ]}
                    loop={Infinity}
                    wrapper="p"
                  />
                </h1>
                <p className="mb-1x">{introdata.description}</p>
                <div className="intro_btn-action pt-2 pb-5">
                  <Link to="/photography" className="text_2">
                    <div id="button_p" className="ac_btn btn ">
                      Photography
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                  </Link>
                  <Link to="/videography">
                    <div id="button_h" className="ac_btn btn">
                      Videography
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                  </Link>
                  {/* <Link to="/store">
                    <div id="button_p" className="ac_btn btn prints">
                      Prints
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                  </Link> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
}
