import React, { useEffect } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../content_option";
import { Player } from 'video-react';
import { Link } from "react-router-dom";
import "../mainPage.css";
import "./videoPlayer.css";


const commercialsContent = [
  {
    last: false, imageLocation: "Left", urlImage: "./img/Site/commercials/Commercial1.png", title: "Dorsya", desc: "The project is an ad for watch company. Dorsya is a brand from London, UK. The concept of the ad was integrating the watch in nature and fashion. ", videoUrl: "./img/Site/commercials/Dorsya.mp4", text: "Commercial for Dorsya"
  },
  {
    last: true, imageLocation: "Right", urlImage: "./img/Site/commercials/Commercial2.png", title: "Dlights", desc: "The project is an ad for glasses company. Dlights is a brand from Gotemburgo, Sweden. The concept of the ad was integrating the glasses as a fashionble fun accessory.", videoUrl: "./img/Site/commercials/Dlights.mp4", text: "Commercial for Dlights"
  }
]

export default function Commercials() {


  return (
    <HelmetProvider>
      <Container className="About-header margin-bottom-3">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Commercials | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>



        {commercialsContent.map((e, key) =>
          <>
            <Row className="mb-2 mt-3">
              <Col lg="8">
                <h1 className="display-4 mb-4 mt-4">Commercials > {e.title}   </h1>{" "}
                <hr className="t_border my-4 ml-0 text-left" />
              </Col>
              <h3 className="absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>

            </Row>
            <div className="container ">
            <div className="ab_block my-6 ">
              <p> {e.desc} </p>
            </div>
            <div className=" ab_block imgs_gallery ">
              <div className="w-full px-8 pt-6 pb-6  imgs_gallery  ">
                <div className=" mx-auto py-6">
                  <Player poster={e.urlImage}>
                    <source src={e.videoUrl} />
                  </Player>
                </div>
               
              </div>
            </div>
          </div>
          </>

          )}


    </Container>

    </HelmetProvider >
  );
}
