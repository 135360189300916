import React, {useEffect, useState} from "react";
import './landingPage.css';
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col, Button } from "react-bootstrap";
import { meta } from "../../content_option";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "../mainPage.css";
import PriceTables from './priceTable';

export default function RealEstatePricesPage() {
return (
    <PriceTables></PriceTables>
    );
}
