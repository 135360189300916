import React, { useState, useEffect} from "react";
import "./style.css";
import { VscGrabber, VscClose } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { socialprofils } from "../content_option";
// import Themetoggle from "../components/themetoggle";
import { PopupButton } from '@typeform/embed-react'

import { IconButton, Badge } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';

import useStyles from './styles';

const Headermain = ( {totalItems} ) => {
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
    document.body.classList.toggle("ovhidden");
  };
  useEffect(() => {
    if (document.documentElement.getAttribute('data-theme')==="light"){
      document.getElementsByClassName("navbar-brand")[0].getElementsByTagName("img")[0].setAttribute("class", document.getElementsByClassName("navbar-brand")[0].getElementsByTagName("img")[0].getAttribute("class") + " light ");
    }

    // document.getElementsByClassName("nav_ac moon")[0].onclick = function (e) {
    //   if( !document.getElementsByClassName("navbar-brand")[0].getElementsByTagName("img")[0].getAttribute("class").includes("light")){
    //     document.getElementsByClassName("navbar-brand")[0].getElementsByTagName("img")[0].setAttribute("class", document.getElementsByClassName("navbar-brand")[0].getElementsByTagName("img")[0].getAttribute("class") + " light ");
    //   }else{
    //     document.getElementsByClassName("navbar-brand")[0].getElementsByTagName("img")[0].setAttribute("class",document.getElementsByClassName("navbar-brand")[0].getElementsByTagName("img")[0].getAttribute("class").replace(" light ",""));
    //   }
    // }
  }) ;
  const classes = useStyles();
  // if(totalItems==undefined) return 'Loading...';
  // console.log(totalItems);
  return (
    <>
      <header className="fixed-top site__header">
        <div className="announcement-bar">
          <p>New collection launched - "Saints"</p>
          </div>
        <div className="d-flex align-items-center justify-content-between header_bg">
          <Link className="navbar-brand nav_ac" to="/">
            <img src="./img/logo_signature.png" alt="Logo" className="h-auto invert w-48"/>
          </Link>
          <div className="d-flex align-items-center">
            {/* moon collor change */}
            {/* <Themetoggle /> */}
            <button className="menu__button  nav_ac" onClick={handleToggle}>
              {!isActive ? <VscClose /> : <VscGrabber />}
            </button>
            <IconButton component={Link} to="/cart" aria-label="Show cart items" color="inherit">
              <Badge badgeContent={totalItems} color="secondary">
                <ShoppingCart/>
              </Badge>
            </IconButton>
          </div>
        </div>
        <div className={`site__navigation ${!isActive ? "menu__opend" : ""}`}>
          <div className="bg__menu h-100">
            <div className="menu__wrapper">
              <div className="menu__container p-3">
                <ul className="the_menu">
                  <li className="menu_item ">
                    <Link onClick={handleToggle} to="/" className="my-3">
                      Home
                    </Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/photography" className="my-3">
                      {" "}
                      Photography
                    </Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/videography" className="my-3">
                      {" "}
                      Videography
                    </Link>
                  </li>
                  <li className="menu_item">
                    <Link onClick={handleToggle} to="/aboutme" className="my-3">
                      {" "}
                      About Me
                    </Link>
                  </li>
                  <li className="menu_item">
                    <PopupButton className="my-3" id="U8KaN4Uc" buttonText="Contact me" >
                      Contact me
                    </PopupButton>
                  </li>
                  {/* <li className="menu_item">
                    <Link onClick={handleToggle} to="#" className="my-3">
                      {" "}
                      Etc{" "}
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="menu_footer d-flex flex-column flex-md-row justify-content-between align-items-md-center position-absolute w-100 p-3">
            <div className="d-flex">
              <a href={socialprofils.email}>Email</a>
              <a href={socialprofils.instagram}>Instagram</a>
              <a href={socialprofils.github}>Github</a>
            </div>
            <hr className="mobile_hr t_border my-2 ml-0 text-left"/>
            <p className="copyright m-0">Copyright © 2022 Afonso Rufino aka Swx Portfólio by Me </p>
          </div>
        </div>
      </header>
      <div className="br-top"></div>
      <div className="br-bottom"></div>
      <div className="br-left"></div>
      <div className="br-right"></div>
    </>
  );
};

export default Headermain;
