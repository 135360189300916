import React, { useEffect } from "react";
import "../../style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../../../content_option";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import "../../../mainPage.css";

const imagesArray = [
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b1.webp", alt: "galeria photoshot b&b 1"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b2.webp", alt: "galeria photoshot b&b 2"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b3.webp", alt: "galeria photoshot b&b 3"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b4.webp", alt: "galeria photoshot b&b 4"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b5.webp", alt: "galeria photoshot b&b 5"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b6.webp", alt: "galeria photoshot b&b 6"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b7.webp", alt: "galeria photoshot b&b 7"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b8.webp", alt: "galeria photoshot b&b 8"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b9.webp", alt: "galeria photoshot b&b 9"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b10.webp", alt: "galeria photoshot b&b 10"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b11.webp", alt: "galeria photoshot b&b 11"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b12.webp", alt: "galeria photoshot b&b 12"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b13.webp", alt: "galeria photoshot b&b 13"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b14.webp", alt: "galeria photoshot b&b 14"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b15.webp", alt: "galeria photoshot b&b 15"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b16.webp", alt: "galeria photoshot b&b 16"
  },
  {
    img: "./img/Site/photoshots/engagement/b&b/b&b17.webp", alt: "galeria photoshot b&b 17"
  }


]

export default function Post2() {
  useEffect(() => {

    if (document.querySelectorAll(".imgs_gallery img") !== undefined) {

      document.querySelector(".imgs_gallery").onmouseover = function (e) {
        for (let i = 0; i < document.querySelectorAll(".imgs_gallery img").length; i++) {
          document.querySelectorAll(".imgs_gallery img")[i].onmouseover = function (e) {

            if (window.innerWidth <= 750) {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.1)';
            } else {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.5)';
            }

            e.target.style.zIndex = '10';

          }
          document.querySelectorAll(".imgs_gallery img")[i].onmouseout = function (e) {

            e.target.style.transform = 'rotate(0) scale(1)';
            e.target.style.zIndex = '1';
            e.target.style.marginLeft = '0em';

          }
        }

      }
    }
  }, []);

  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Engagement Photoshot | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-2 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Engagement Photoshots > B&B </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
          <h3 className="absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>

        </Row>
        <div className="container ab_block_desc mb-6 ">
          <p>Bernardo and Beatriz wanted me to make an engagement photoshot for them before their marriage.</p>
        </div>
        <div className="container ab_block imgs_gallery ">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry
              gutter={"10px"}
            >
              {imagesArray.map((e, key) =>
                <>
                  <img className="shadow-2xl bg-white gallery_img" key={key} src={e.img} alt={e.alt} style={{width: "100%", display: "block"}} />
                </>
              )}
            </Masonry>
          </ResponsiveMasonry>
        </div>

      </Container>
    </HelmetProvider>
  );
}
