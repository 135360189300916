import React, { useEffect } from "react";
import "./../../style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../../../content_option";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import "../../../mainPage.css";

const imagesArray = [
  {
    img: "./img/Site/photoshots/engagement/s&a/s&a1.webp", alt: "galeria photoshot s&a 1"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a2.webp", alt: "galeria photoshot s&a 2"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a3.webp", alt: "galeria photoshot s&a 3"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a4.webp", alt: "galeria photoshot s&a 4"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a5.webp", alt: "galeria photoshot s&a 5"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a6.webp", alt: "galeria photoshot s&a 6"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a7.webp", alt: "galeria photoshot s&a 7"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a8.webp", alt: "galeria photoshot s&a 8"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a9.webp", alt: "galeria photoshot s&a 9"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a10.webp", alt: "galeria photoshot s&a 10"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a11.webp", alt: "galeria photoshot s&a 11"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a12.webp", alt: "galeria photoshot s&a 12"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a13.webp", alt: "galeria photoshot s&a 13"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a14.webp", alt: "galeria photoshot s&a 14"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a15.webp", alt: "galeria photoshot s&a 15"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a16.webp", alt: "galeria photoshot s&a 16"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a17.webp", alt: "galeria photoshot s&a 17"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a18.webp", alt: "galeria photoshot s&a 18"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a19.webp", alt: "galeria photoshot s&a 19"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a20.webp", alt: "galeria photoshot s&a 20"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a21.webp", alt: "galeria photoshot s&a 21"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a22.webp", alt: "galeria photoshot s&a 22"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a23.webp", alt: "galeria photoshot s&a 23"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a24.webp", alt: "galeria photoshot s&a 24"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a25.webp", alt: "galeria photoshot s&a 25"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a26.webp", alt: "galeria photoshot s&a 26"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a27.webp", alt: "galeria photoshot s&a 27"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a28.webp", alt: "galeria photoshot s&a 28"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a29.webp", alt: "galeria photoshot s&a 29"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a30.webp", alt: "galeria photoshot s&a 30"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a31.webp", alt: "galeria photoshot s&a 31"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a32.webp", alt: "galeria photoshot s&a 32"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a33.webp", alt: "galeria photoshot s&a 33"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a34.webp", alt: "galeria photoshot s&a 34"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a35.webp", alt: "galeria photoshot s&a 35"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a36.webp", alt: "galeria photoshot s&a 36"
  },
  {
    img: "./img/Site/photoshots/engagement/s&a/s&a37.webp", alt: "galeria photoshot s&a 37"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a38.webp", alt: "galeria photoshot s&a 38"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a39.webp", alt: "galeria photoshot s&a 39"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a40.webp", alt: "galeria photoshot s&a 40"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a41.webp", alt: "galeria photoshot s&a 41"
  },{
    img: "./img/Site/photoshots/engagement/s&a/s&a42.webp", alt: "galeria photoshot s&a 42"
  },
 


]

export default function Post2() {
  useEffect(() => {

    if (document.querySelectorAll(".imgs_gallery img") !== undefined) {

      document.querySelector(".imgs_gallery").onmouseover = function (e) {
        for (let i = 0; i < document.querySelectorAll(".imgs_gallery img").length; i++) {
          document.querySelectorAll(".imgs_gallery img")[i].onmouseover = function (e) {

            if (window.innerWidth <= 750) {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.1)';
            } else {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.5)';
            }

            e.target.style.zIndex = '10';

          }
          document.querySelectorAll(".imgs_gallery img")[i].onmouseout = function (e) {

            e.target.style.transform = 'rotate(0) scale(1)';
            e.target.style.zIndex = '1';
            e.target.style.marginLeft = '0em';

          }
        }

      }
    }
  }, []);

  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Engagement Photoshots | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-2 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"><a link="/engagement_ps">Engagement Photoshots </a> > S&A </h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
          <h3 className="absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>

        </Row>
        <div className="container ab_block_desc mb-6 ">
          <p>Love isn’t just a feeling; it’s moments, memories, and melodies. Here’s a visual diary of their love. </p>
          <p className="bold">Sergiu & Ani</p>
        </div>
        <div className="container ab_block imgs_gallery ">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry
              gutter={"10px"}
            >
              {imagesArray.map((e, key) =>
                <>
                  <img className="shadow-2xl bg-white gallery_img" key={key} src={e.img} alt={e.alt} style={{width: "100%", display: "block"}} />
                </>
              )}
            </Masonry>
          </ResponsiveMasonry>
        </div>

      </Container>
    </HelmetProvider>
  );
}
