import React from "react";
import { Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import Home from "../pages/home";
import Post from "../pages/post";
import Post2 from "../pages/post2";
import Photography from "../pages/index/photography";
import Videography from "../pages/index/videography";
import MusicVideos from "../pages/videography/musicVideos";
import Demo from "../pages/demo";
import ConcertCoverage from "../pages/photography/syro_concert"
import StudioPortraits from "../pages/photography/studio_portraits"
import HotelPhotography from "../pages/photography/hotel_photography"
import Airbnb from "../pages/photography/airbnb"
import RealEstate from "../pages/photography/realestatelanding"
import RealEstatePrices from "../pages/photography/realestateprices"
import EngagementPhotoshot from "../pages/photography/engagement_ps/engagement_ps"
import BBep from "../pages/photography/engagement_ps/b&b/b&b"
import SAep from "../pages/photography/engagement_ps/s&a/s&a"
import Stories from "../pages/videography/stories";
import Commercials from "../pages/videography/commercials";
import AboutMe from "../pages/aboutme";




function AppRoutes(  ) {
 
  return (
    <AnimatedSwitch
      atEnter={anim.atEnter}
      atLeave={anim.atLeave}
      atActive={anim.atActive}
      mapStyles={mapStyles}
      className="page"
    >
      <Route exact path="/" component={Home} />
      <Route path="/post" component={Post} />
      <Route path="/post2" component={Post2} />
      <Route path="/photography" component={Photography} />
      <Route path="/videography" component={Videography} />
      <Route path="/musicvideos" component={MusicVideos} />
      <Route path="/stories" component={Stories} />
      <Route path="/demo" component={Demo} />
      <Route path="/studioPortraits" component={StudioPortraits} />
      <Route path="/hotelPhotography" component={HotelPhotography} />
      <Route path="/airbnb" component={Airbnb} />
      <Route path="/realestate" component={RealEstate} />
      <Route path="/realestateprices" component={RealEstatePrices} />
      <Route path="/concertCoverage" component={ConcertCoverage} />
      <Route path="/engagementPhotoshots" component={EngagementPhotoshot}/>
        <Route path="/s-a" component={SAep} />
        <Route path="/b-b" component={BBep} />
      {/* </Route> */}
      <Route path="/commercials" component={Commercials} />
      <Route path="/aboutme" component={AboutMe} />
      {/* <Route path='/store' component={() => {
          window.location.href = 'https://shop.afonsorufino.com';
          return null;
        }}/> */}
        <Route path='/cart' component={() => {
          window.location.href = 'https://shop.afonsorufino.com/cart';
          return null;
        }}/>
      <Route path="*" component={Home} />
    </AnimatedSwitch>
  );
}

function mapStyles(styles) {
  return {
    transition: `transform 100ms ease`,
    transform: `translateY(${styles.translateY}%)`
  };
}

const anim = {
  atEnter: {
    translateY: 100
  },
  atLeave: {
    translateY: -130
  },
  atActive: {
    translateY: 0
  }
};

export default AppRoutes;
