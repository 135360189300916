import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../content_option";
import { Player } from 'video-react';
// import { Link } from "react-router-dom";
import "../mainPage.css";
import "./videoPlayer.css";

const documentaryContent = [
  {
    last: false, imageLocation: "Left", urlImg: "./img/Site/commercials/logo-reakiro.png", title: "Documentary for Reakiro", desc: "Reakiro wanted a short documentary that described well the company as leading CBD company.", videoUrl: "./img/Site/commercials/Reakiro.mp4", text: "Documentary for Reakiro"
  }
];

export default function Stories() {


  return (
    <HelmetProvider>
      <Container className="About-header">

        <Helmet>
          <meta charSet="utf-8" />
          <title> Stories | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-2 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Stories > {documentaryContent[0].text}   </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
          <h3 className="absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>

        </Row>
        <div className="container ab_block_desc mb-6 ">
        <p> {documentaryContent[0].desc} </p>
        </div>
        <div className="container ab_block imgs_gallery ">
          {documentaryContent.map((e, key) =>

            <div key={key} className="w-full contents  px-8 pb-32 -mb-24 div_commercials ">
            
                <div className=" mx-auto py-6">
                  <Player poster="./img/Site/stories/stories.jpg">
                    <source src={e.videoUrl} />
                  </Player>
                </div>
                <h4 className="text-center text-white">{e.title}</h4>
              </div>



          )}
        </div>

      </Container>

    </HelmetProvider>
  );
}
