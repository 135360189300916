import React, { useEffect } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../content_option";
import { Player } from 'video-react';
import { Link } from "react-router-dom";
import "../mainPage.css";
import "./videoPlayer.css";


const lowkeyContentArray = [
  {
    img: "./img/Site/musicVideo/ricko_Lowkey/Artwork-lowkey-min.jpg", alt: "galeria lowkey artwork"
  },
  {
    img: "./img/Site/musicVideo/ricko_Lowkey/Thumbnail-min.jpg", alt: "galeria lowkey thumbnail", videoUrl: "https://www.youtube.com/watch?v=FKZThNEChyo"
  },
  {
    videoUrl: "./img/Site/musicVideo/ricko_Lowkey/Reel_Lowkey-.mp4", text: "LOWKEY's instagram teaser", imageUrl:"./img/Site/musicVideo/ricko_Lowkey/musicVideo_tb1.png"
  },
  {
    videoUrl: "./img/Site/musicVideo/ricko_Lowkey/Reel_Lowkey-_1.mp4", text: "LOWKEY's instagram ad", imageUrl:"./img/Site/musicVideo/ricko_Lowkey/musicVideo_tb2.png"
  }
  ,
  {
    videoUrl: "./img/Site/musicVideo/ricko_Lowkey/Spotify_Canva.mp4", text: "LOWKEY's spotify canva", imageUrl:"./img/Site/musicVideo/ricko_Lowkey/musicVideo_tb3.png"
  }


]

export default function MusicVideos() {


  return (
    <HelmetProvider>
      <Container className="About-header">

        <Helmet>
          <meta charSet="utf-8" />
          <title> Music Videos | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-2 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Music Videos > LOWKEY</h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
          <h3 className="absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>

        </Row>
        <div className="container ab_block_desc mb-6 ">
          <p>Music video for the underground artist Ricko Sicko. It was challenging to be on the production, as a director and as the script writter, since it was a new position for me. You can see the outcome below :)</p>
          <p>Also made the thumbnail, instagram ad, instagram teaser, Spotify canva and artwork.</p>        </div>
        <div className="container ab_block imgs_gallery ">
              {/* <Link href={lowkeyContentArray[1].videoUrl}> */}
                <a href={lowkeyContentArray[1].videoUrl}className="w-8/12 mx-auto" target="_blank">
                  <img className="w-8/12 mx-auto py-4" src={lowkeyContentArray[1].img} alt={lowkeyContentArray[1].alt} />
                </a>
              {/* </Link> */}
              <h4 className="text-center text-white">Ricko Sicko - LOWKEY</h4>
              <div className="w-full mx-auto py-6">
                <div className="w-1/3 mx-auto">
                <Player className="py-4" poster={lowkeyContentArray[2].imageUrl}>
                  <source src={lowkeyContentArray[2].videoUrl} />
                </Player>
                </div>
                <h4 className="text-center text-white">{lowkeyContentArray[2].text}</h4>
              </div>
              <div className="w-full mx-auto py-6">
                <div className="w-1/3 mx-auto">
                <Player className="py-4" poster={lowkeyContentArray[3].imageUrl}>
                  <source src={lowkeyContentArray[3].videoUrl} />
                </Player>
                </div>
                <h4 className="text-center text-white">{lowkeyContentArray[3].text}</h4>
              </div>
              <div className="w-full mx-auto py-6">
                <div className="w-1/3 mx-auto">
                <Player className="py-4" poster={lowkeyContentArray[4].imageUrl}>
                  <source src={lowkeyContentArray[4].videoUrl} />
                </Player>
                </div>
                <h4 className="text-center text-white">{lowkeyContentArray[4].text}</h4>
              </div>
              
            
        </div>

      </Container>

    </HelmetProvider>
  );
}
