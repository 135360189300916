import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import {
  meta
} from "../../content_option";

const aboutArray = [
  {
    img: "./img/Site/about/profile_pic.jpg", alt: "Profile Picture"
  }
]

const logosAbout = [
  {
    url: "./img/Site/commercials/logo-reakiro.png", alt: "Logo Reakiro"
  }
  , {
    url: "./img/Site/logos_about/logo_mln.png", alt: "Logo Mln Studio"
  }
  , {
    url: "./img/Site/logos_about/airbnb-logo-5-1.png", alt: "Logo Airbnb"
  }

]

const tableContent = [
  {
    type: "Work History", content: [
      {
        text: "NODE Creations |", text2: "2022 - 2023"
      },
      {
        text: "Freelancing |", text2: "Since 2022"
      }

    ]//content 
  },
  {
    type: "Clients", content: [

      {
        text: "Dlights"
      },
      {
        text: "Dorsya"
      },
      {
        text: "Reakiro"
      },
      {
        text: "Mln Studios"
      }
    ]//content 
  },
  {
    type: "Specialities", content: [
      {
        text: "Video Clips"
      },
      {
        text: "Concert Photography"
      },
      {
        text: "Studio Photography"
      },{
        text: "Real State Photography"
      },{
        text: "Product Photography"
      },

    ]//content 
  },
  {
    type: "Contacts", content: [
      {
        text: "geral@afonsorufino.com", link: "mailto:geral@afonsorufino.com"
      },
      {
        text: "(+351)918067322", link: "tel:+351918067322"
      }
    ]//content 
  }
]//tableContent

export default function Post() {
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> About meta | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        
        <Row className="mb-5 mt-1">
          <Col lg="5">
            <h2 className="display-4 mb-4">About me</h2>
            <hr className="t_border my-2 ml-0 text-left" />
          </Col>
          <h3 className=" container absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>

        </Row>

        <div className="w-full  flex  px-8 ">
          <div className="w-1/2 width_mobile self-center mobile_left_about px-6 pb-6 sm:px-0">
            <img className="w-1/2 width_mobile mx-auto title_image_desk rounded-full h-1/2 w-1/2" src={aboutArray[0].img} alt={aboutArray[0].alt} />
          </div>
          <div className="w-1/2 width_mobile flex flex-col	justify-center pb-6">
            <h2 className="strong center_text_mobile text-5xl pb-6">Afonso Rufino</h2>
            <img className="w-1/2 profile_mobile_img mx-auto title_image_mobile rounded-full h-1/2 w-1/2" src={aboutArray[0].img} alt={aboutArray[0].alt} />

            <a href="mailto:geral@afonsorufino.com"><h4 className="center_text_mobile">geral@afonsorufino.com</h4></a>
            <br />
            <p className="center_text_mobile">Freelancer Videographer and Photographer.</p>
            <p className="center_text_mobile">Based in Torres Vedras, Portugal. Creative worldwide.</p>
          </div>
        </div>
        
          <div className="w-full container selected_clients mx-auto px-6 pb-6 pt-12">
            <h2 className="text-3xl text-black strong_g text-center">Selected Clients</h2>
            <div className="w-full flex flex-wrap justify-center">
              {logosAbout.map((e, key) =>
                <div key={key} className="w-1/3 self-center text-center_webkit ">
                  <img src={e.url} alt={e.alt} />
                </div>
              )}
            </div>

          </div>
        
          <div className="w-full ab-table table_mobile  lg:px-20 md:px-20 sm:px-10 pt-12 pb-20 -mb-20  imgs_gallery py-6 ">

            <table className="w-full text_var ">
              <thead>
                <tr>
                  <th className="w-1/4 px-4 text-center_webkit text-3xl strong text-black">{tableContent[0].type}</th>
                  <th className="w-1/4 px-4 text-center_webkit text-3xl strong text-black">{tableContent[1].type}</th>
                  <th className="w-1/4 px-4 text-center_webkit text-3xl strong text-black">{tableContent[2].type}</th>
                  <th className="w-1/4 px-4 text-center_webkit text-3xl strong text-black">{tableContent[3].type}</th>
                </tr>
              </thead>
              <tbody>
             
                <td className="w-1/4 text-center_webkit text-xl text-black">
                  {tableContent[0].content.map((e, key) =>
                    <tr key={key}>{e.text + " " + e.text2}</tr>
                  )}
                </td>
                <td className="w-1/4 text-center_webkit text-xl text-black">
                  {tableContent[1].content.map((e, key) =>
                    <tr key={key}>{e.text}</tr>
                  )}
                </td>
                <td className="w-1/4 text-center_webkit text-xl text-black">
                  {tableContent[2].content.map((e, key) =>
                    <tr key={key}>{e.text}</tr>
                  )}
                </td>
                <td className="w-1/4 text-center_webkit text-xl text-black">
                  {tableContent[3].content.map((e, key) =>
                    <tr key={key}><a href={e.link} target="_blank" rel="noreferrer noopener" >{e.text}</a></tr>
                  )}
                </td>



              </tbody>
            </table>

          </div>
         
        
      </Container>
    </HelmetProvider>
  );
}
