import React, { useEffect } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../content_option";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import "../mainPage.css";
import { Link } from "react-router-dom";

const imagesArray = [
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_1.webp", alt: "galeria syro 1"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_2.webp", alt: "galeria syro 2"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_3.webp", alt: "galeria syro 3"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_4.webp", alt: "galeria syro 4"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_5.webp", alt: "galeria syro 5"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_6.webp", alt: "galeria syro 6"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_7.webp", alt: "galeria syro 7"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_8.webp", alt: "galeria syro 8"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_9.webp", alt: "galeria syro 9"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_10.webp", alt: "galeria syro 10"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_11.webp", alt: "galeria syro 11"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_12.webp", alt: "galeria syro 12"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_13.webp", alt: "galeria syro 13"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_14.webp", alt: "galeria syro 14"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_15.webp", alt: "galeria syro 15"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_16.webp", alt: "galeria syro 16"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_17.webp", alt: "galeria syro 17"
  },
  {
    img: "./img/Site/concertCoverage/syro/galeria/galeria_syro_18.webp", alt: "galeria syro 18"
  }

]

export default function Post2() {
  useEffect(() => {

    if (document.querySelectorAll(".imgs_gallery img") !== undefined) {

      document.querySelector(".imgs_gallery").onmouseover = function (e) {
        for (let i = 0; i < document.querySelectorAll(".imgs_gallery img").length; i++) {
          document.querySelectorAll(".imgs_gallery img")[i].onmouseover = function (e) {

            if (window.innerWidth <= 750) {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.1)';
            } else {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.5)';
            }

            e.target.style.zIndex = '10';

          }
          document.querySelectorAll(".imgs_gallery img")[i].onmouseout = function (e) {

            e.target.style.transform = 'rotate(0) scale(1)';
            e.target.style.zIndex = '1';
            e.target.style.marginLeft = '0em';

          }
        }

      }
    }
  }, []);

  return (
    <HelmetProvider>
      <Container className="About-header">

        <Helmet>
          <meta charSet="utf-8" />
          <title> Concert Coverage | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-2 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"> Concerts Coverage > Syro</h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
          <h3 className="absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>

        </Row>
        <div className="container ab_block_desc mb-6 ">
          <p>Went on a 2 day in a row show with the rising portuguese pop artist Syro, to coverage the two shows as a photographer and social media content creator.</p>
        </div>
        <div className="container ab_block imgs_gallery ">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry
              gutter={"10px"}
            >
              {imagesArray.map((e, key) =>
                <img className="shadow-2xl bg-white gallery_img" key={key} src={e.img} alt={e.alt} style={{width: "100%", display: "block"}} />
              )}
            </Masonry>
          </ResponsiveMasonry>
        </div>

      </Container>

    </HelmetProvider>
  );
}
