/* This is metadata section for SEO
Probably you can edit this section without manually editing the component
*/

// metadata
const logotext = "Afonso Rufino";
const meta = {
  title: "Afonso Rufino Portfolio",
  description: "This is Afonso Rufino Video and Photo portfolio"
};

// hero animated for intro
const introdata = {
  title: "Welcome to",
  animated: {
    first: "Afonso Rufino Videography",
    second: "Afonso Rufino Photography"
    // third: "React Native"
  },
  description: "To get started , please click button below",
  // img_url: "https://wallpaperaccess.com/full/3949076.jpg"
  img_url: "./img/Site/concertCoverage/syro/Syro_Thumbail_.jpg"
};

// data section , for portfolio or about me
const dataabout = {
  title: "Title",
  aboutme: "About . "
};
const worktimeline = [
  {
    jobtitle: "test",
    where: "test",
    date: "2020"
  },
  {
    jobtitle: "test",
    where: "test",
    date: "2019"
  },
  {
    jobtitle: "test",
    where: "test",
    date: "2019"
  }
];

const tech = [
  {
    name: "Progress 1",
    value: 98
  },
  {
    name: "Progress 2",
    value: 85
  },
  {
    name: "Progress 3",
    value: 50
  },
  {
    name: "Progress 4",
    value: 95
  }
];

const services = [
  {
    title: "Site",
    description: "https://yoururl.com"
  },
  {
    title: "Site",
    description: "https://yoururl.com"
  },
  {
    title: "Site ",
    description: "https://yoururl.com"
  }
];

// showcase your content here
const dataportfoliophoto = [
  {
    img: "./img/Site/concertCoverage/syro/Syro_Thumbail_.webp",
    desctiption: "Concert Coverage",
    link: "../concertCoverage"
  },
  {
    img: "./img/Site/photoshots/engagement/sa_banner.webp",
    desctiption: "Engagement Photoshots",
    link: "../engagementPhotoshots"
  },
  /*{
    img: "./img/Site/photoshots/self_portrait/studio_portrait.webp",
    desctiption: "Studio Portraits",
    link: "../StudioPortraits"
  },*/
  {
    img: "./img/Site/photoshots/hotel_photography/promar/capa.webp",
    desctiption: "Hotel Photography",
    link: "../hotelPhotography"
  },
  {
    img: "./img/Site/photoshots/airbnb/airbnb_cover.jpg",
    desctiption: "Airbnb Photography",
    link: "../airbnb"
  }
  // ,
  // {
  //   img: "./img/Site/photoshots/engagement/b&b/b&b11.jpg",
  //   desctiption: "Street Photography",
  //   link: "../streetPhotoshots"
  // }
  // ,
  // {
  //   img: "https://miro.medium.com/max/2000/1*-Ijet6kVJqGgul6adezDLQ.png",
  //   desctiption: "Title",
  //   link: "../post2"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "../post2"
  // }
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },

  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // }
];
const ph_engagementdataportfoliophoto = [
  {
    img: "./img/Site/photoshots/engagement/sa_banner.webp",
    desctiption: "S&A Photoshots",
    link: "../s-a"
  },{
    img: "./img/Site/photoshots/engagement/b&b/b&b11.webp",
    desctiption: "B&B Photoshot",
    link: "../b-b"
  }

];

const dataportfoliovideo = [
  {
    img: "./img/Site/musicVideo/ricko_Lowkey/Thumbnail-min.jpg",
    desctiption: "Music Videos",
    link: "../musicvideos"
  },
  {
    img: "./img/Site/stories/stories.jpg",
    desctiption: "Stories",
    link: "../stories"
  },
  {
    img: "./img/Site/commercials/commercial.jpg",
    desctiption: "Commercials",
    link: "../commercials"
  }
  // ,
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "../post2"
  // }
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },

  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // },
  // {
  //   img: "https://miro.medium.com/max/1400/1*CeuWv9fCjD1uTiTuKytnBQ.png",
  //   desctiption: "Title",
  //   link: "#"
  // }
];

// contact
const contactConfig = {
  YOUR_EMAIL: "geral@afonsorufino.com",
  YOUR_FONE: "(+351)918067322",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vehicula eu nunc et sollicitudin. Cras pulvinar, nisi at imperdiet pharetra. ",
  YOUR_SERVICE_ID: "service_id",
  YOUR_TEMPLATE_ID: "template_id",
  YOUR_USER_ID: "user_id"
};

const socialprofils = {
  instagram: "https://www.instagram.com/afonso_rufino/",
  github: "https://github.com/swx-jpg",
  // linkedin: "https://linkedin.com",
  email: "mailto:geral@afonsorufino.com"
};
export {
  meta,
  dataabout,
  dataportfoliophoto,
  ph_engagementdataportfoliophoto,
  dataportfoliovideo,
  worktimeline,
  tech,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext
};
