import React, { useEffect } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../content_option";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import "../mainPage.css";
import { Link } from "react-router-dom";

const imagesArray = [
  {
    img: "./img/Site/photoshots/self_portrait/self_portrait1.webp", alt: "galeria photoshot self_portrait 1"
  },
  {
    img: "./img/Site/photoshots/self_portrait/self_portrait2.webp", alt: "galeria photoshot self_portrait 2"
  },
  {
    img: "./img/Site/photoshots/self_portrait/self_portrait4.webp", alt: "galeria photoshot self_portrait 4"
  },
  {
    img: "./img/Site/photoshots/self_portrait/self_portrait5.webp", alt: "galeria photoshot self_portrait 5"
  },
  {
    img: "./img/Site/photoshots/self_portrait/self_portrait3.webp", alt: "galeria photoshot self_portrait 3"
  }


]

export default function Post2() {
  useEffect(() => {

    if (document.querySelectorAll(".imgs_gallery img") !== undefined) {

      document.querySelector(".imgs_gallery").onmouseover = function (e) {
        for (let i = 0; i < document.querySelectorAll(".imgs_gallery img").length; i++) {
          document.querySelectorAll(".imgs_gallery img")[i].onmouseover = function (e) {

            if (window.innerWidth <= 750) {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.1)';
            } else {
              e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.5)';
            }

            e.target.style.zIndex = '10';

          }
          document.querySelectorAll(".imgs_gallery img")[i].onmouseout = function (e) {

            e.target.style.transform = 'rotate(0) scale(1)';
            e.target.style.zIndex = '1';
            e.target.style.marginLeft = '0em';

          }
        }

      }
    }
  }, []);

  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Night Photoshots | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-2 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Studio portraits > Me</h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
          <h3 className="absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>

        </Row>
        <div className="container ab_block_desc mb-6 ">
          <p>Portraits are the art of showing people character by a mere frame.</p>
          <p>This studio portrait was a project born as for me to self portraits. Was quite chalanging to keep myself in frame</p>
        </div>
        <div className="container ab_block imgs_gallery ">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry
              gutter={"10px"}
            >
              {imagesArray.map((e, key) =>
                <>
                  {/* <img className="shadow-2xl bg-white p-2 " key={key} src={e.img} alt={e.alt} /> */}
                  <img className="shadow-2xl bg-white gallery_img" key={key} src={e.img} alt={e.alt} style={{width: "100%", display: "block"}} />
                </>
              )}
            </Masonry>
          </ResponsiveMasonry>
        </div>

      </Container>
    </HelmetProvider>
  );
}
