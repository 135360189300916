                            import React, {useEffect, useState} from "react";
                            import './landingPage.css';
                            import "./style.css";
                            import { Helmet, HelmetProvider } from "react-helmet-async";
                            import { Container, Row, Col, Button } from "react-bootstrap";
                            import { meta } from "../../content_option";
                            import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
                            import "../mainPage.css";
                            import PackageCard from './packageCard';
                            import AboutMe from './aboutme.css';
                            import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faInstagram } from '@fortawesome/free-brands-svg-icons';



                            // ... (Your imagesArray and other constants)
                            const packages = [
                              {
                                image: './img/Site/photoshots/hotel_photography/promar/boards/2.jpg',
                                title: 'BASE',
                                size: 'ATÉ 80M²',
                                services: ['drone', 'other_service'],
                                description: 'Um pacote completo para apartamentos mais pequenos, incluí fotos aéreas com drone.',
                                price: '140',
                              },{
                                image: './img/Site/photoshots/hotel_photography/promar/boards/4.jpg',
                                title: 'STANDARD',
                                size: 'ATÉ 140M²',
                                services: ['drone', 'other_service'],
                                description: 'Um pacote completo para apartamentos até 140M², incluí fotos aéreas com drone.',
                                price: '180',
                              },{
                                image: './img/Site/photoshots/hotel_photography/promar/boards/3.jpg',
                                title: 'AVANÇADO',
                                size: 'ATÉ 200M²',
                                services: ['drone', 'other_service'],
                                description: 'Um pacote dedicado a apartamentos mais detalhados e maiores, incluí fotos aéreas com drone.',
                                price: '240',
                              },{
                                image: './img/Site/photoshots/hotel_photography/promar/boards/1.jpg',
                                title: 'LUXO',
                                size: 'MAIOR DO QUE 200M²',
                                services: ['drone', 'other_service'],
                                description: 'Um pacote dedicado a apartamentos luxuosos com muitos detalhes e promonores, incluí fotos aéreas com drone.',
                                price: '300',
                              }
                              // ... other packages
                            ];
                            const imagesArray = [
                              {
                                img: "./img/Site/photoshots/hotel_photography/promar/promar_1.webp", alt: "galeria photoshot promar 1"
                              }
                              ,{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar3.webp", alt: "galeria photoshot promar 3"
                              }
                              ,{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar5.webp", alt: "galeria photoshot promar 5"
                              },{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar6.webp", alt: "galeria photoshot promar 6"
                              },{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar7.webp", alt: "galeria photoshot promar 7"
                              },{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar8.webp", alt: "galeria photoshot promar 8"
                              }
                              ,{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar11.webp", alt: "galeria photoshot promar 11"
                              },{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar12.webp", alt: "galeria photoshot promar 12"
                              },{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar13.webp", alt: "galeria photoshot promar 13"
                              },{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar14.webp", alt: "galeria photoshot promar 14"
                              }
                              ,{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar16.webp", alt: "galeria photoshot promar 16"
                              }
                              ,{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar18.webp", alt: "galeria photoshot promar 18"
                              },{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar19.webp", alt: "galeria photoshot promar 19"
                              },{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar20.webp", alt: "galeria photoshot promar 20"
                              },{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar21.webp", alt: "galeria photoshot promar 21"
                              }
                              ,{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar26.webp", alt: "galeria photoshot promar 26"
                              },{
                                img: "./img/Site/photoshots/hotel_photography/promar/promar27.webp", alt: "galeria photoshot promar 27"
                              }
                            ]

                            export default function RealEstateLandingPage() {
                              const [receivedString, setReceivedString] = useState('');
                              const [buttonClicked, setButtonClicked] = useState(false);
                              const [message, setMessage] = useState('');
                            
                              
                              useEffect(() => {
                                const savedButtonState = JSON.parse(localStorage.getItem('buttonState'));
                                const savedReceivedString = localStorage.getItem('receivedString');
                                if (savedButtonState) {
                                  setButtonClicked(savedButtonState);
                                }
                                if (savedReceivedString) {
                                  setReceivedString(savedReceivedString);
                                }
                                if (document.querySelectorAll(".imgs_gallery img") !== undefined) {

                                  document.querySelector(".imgs_gallery").onmouseover = function (e) {
                                    for (let i = 0; i < document.querySelectorAll(".imgs_gallery img").length; i++) {
                                      document.querySelectorAll(".imgs_gallery img")[i].onmouseover = function (e) {

                                        if (window.innerWidth <= 750) {
                                          e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.1)';
                                        } else {
                                          e.target.style.transform = 'rotate(' + Math.floor(Math.random() * (1 + 1 + 1) - 1) + 'deg) scale(1.5)';
                                        }

                                        e.target.style.zIndex = '10';

                                      }
                                      document.querySelectorAll(".imgs_gallery img")[i].onmouseout = function (e) {

                                        e.target.style.transform = 'rotate(0) scale(1)';
                                        e.target.style.zIndex = '1';
                                        e.target.style.marginLeft = '0em';

                                      }
                                    }

                                  }
                                }
                              }, []);
                              const handleButtonClick = (numberString) => {
                                if (!buttonClicked) {
                                  setReceivedString(numberString);
                                  setButtonClicked(true);
                                  localStorage.setItem('buttonState', JSON.stringify(true));
                                  localStorage.setItem('receivedString', numberString);
                                } else {
                                  setMessage('Já votou anteriormente.');
                                }
                              };
                                return (
                                    <HelmetProvider>
                                        <Container className="landing-header">
                                            <Helmet>
                                                <meta charSet="utf-8" />
                                                <title>Real Estate Photography | {meta.title}</title>
                                                <meta name="description" content={meta.description} />
                                            </Helmet>
                                            <Row className="mb-4 mt-4">
                                              
                                            </Row>
                                            <Row className="mb-4 mt-4 mr-2 ml-2">
                                                <Col>
                                                    <h1 className="display-4 title_mobile mb-2">Aumente a Atratividade do Seu Imóvel</h1>
                                                    <br/>
                                                    <div className="boder_important_div">
                                                      <h4 className="border_important">Capturar a verdadeira essencia da sua propriedade fará toda a diferença</h4>
                                                    </div>
                                                    <div className="graph-center mb-2">
                                                      <div className="graphdiv">  
                                                        <img className="graph mt-8" src="./img/Site/photoshots/hotel_photography/graph.jpg"/>
                                                        <p className="subtitle">
                                                          Valorização <b>extra</b> de venda em apartamentos com valores entre <b>200.000€</b> e <b>1.000.000€</b> vendidos com <b>fotografia professional</b>, em comparação com <b>fotografia amadora</b>.
                                                          </p>
                                                      </div>
                                                    </div>
                                                    <p className="mt-4 text-left">
                                                      No gráfico em cima ilustro um estudo da <a href="https://www.redfin.com/blog/professional-real-estate-photos-sell-homes-for-more/"><u>Redfin</u></a> em 22 mercados, onde imóveis frotografados professionalmente com preços entre 200.000€ e 1.000.000€ tiveram uma retabilidade extra de 3.400€ a 11.200€ do valor inicialmente estimado.
                                                      <br/>
                                                      E os quais foram vendidos mais rápidamente com média de 81 dias, em casas com um preço fixo de 400.000€.
                                                      <br/>
                                                      <br/>
                                                      Já reparou o que está a <u>perder ao não escolher</u> um serviço de fotografia professional na listagem dos seus imóveis?
                                                      <ul className="pl-4">
                                                        <li>
                                                          <u>Vendas entre 3.400€ e 11.200€</u> mais altas do que o preço estimado.
                                                        </li>
                                                        <li>
                                                          Vendas mais rápidas com uma <u>média de 81 dias</u>.
                                                        </li>
                                                      </ul>
                                                      <br/>
                                                      <div className="border_important width-max text-center">
                                                      <p>Com fotografia professional não só <u>vai conseguir valores de vendas mais altos</u>, mas também <u>mais vendas no mesmo espaço de tempo</u>.</p>

                                                      </div>
                                                      
                                                    </p>
                                                    {/* <p className="mt-4 text-left">
                                                      O mercado imobiliário é evidentemente aliciante financeireiramente. 
                                                      <br/> 
                                                      Normalmente tem sempre três entidades interessadas.
                                                      <br/>
                                                      <ul className="pl-4" >
                                                        <li>O <b>vendedor</b> que quer maximizar o preço do imóvel.</li>
                                                        <li>O <b>comprador</b> que quer a valorização máxima da sua compra.</li>
                                                        <li>O <b>consultor/agência imobiliária</b> que está focado em maximizar a sua comissão.</li>
                                                      </ul>
                                                      <br/>
                                                      O conceito “é preciso gastar dinheiro para ganhar dinheiro”, faz sentido especialmente na venda de imoveis.
                                                      Pode parecer um pouco contraintuitivo, porém investir na venda de um imóvel irá trazer um <b>retorno maior</b>!
                                                    </p> */}
                                                </Col>
                                            </Row>
                                            <hr/>
                                            <Row className="selling-points mb-4 mr-2 ml-2">
                                                <Col md={12} sm={12} xs={12}>
                                                    <h4>Aumente a Percepção de Valor </h4>
                                                    <p>Fotos de Alta Qualidade aumentam a percepção de valor da sua propriedade.</p>
                                                    <p>Ambas as fotos são de <u>hoteis pertencentes à mesma zona</u>, ambos com propostas idênticas e com menos de 5€ de diferença por 2 pessoas/noite.</p>
                                                    <Row className="mb-4 mt-4" md={12} xs={12}>
                                                    <div className="div_photos" >
                                                      <Col md={6} sm={6} xs={6} className="nosidepad right">
                                                        <div className="wrongphoto ">
                                                        <img src="./img/Site/photoshots/hotel_photography/wrong.jpg"/>
                                                        </div>
                                                      <h2>Fotografia de um concorrente</h2>
                                                      </Col>
                                                      <Col md={6} sm={6} xs={6} className="nosidepad left" >
                                                      <div className="rightphoto ">                                    
                                                        <img src="./img/Site/photoshots/hotel_photography/right.jpg"/>
                                                      </div>
                                                      <h2>Fotografia de um cliente meu</h2>
                                                      </Col>
                                                  
                                                    </div>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <div className="border_important width-max mr-2 ml-2">
                                              <p>Colocando-se na ótica de cliente, qual acabaria por escolher para ficar de férias com a sua família?</p>
                                            </div>
                                            <Row className="mt-4 mr-2 ml-2">
                                              <Col md={3} sm={3} xs={0}> </Col>
                                              <Col md={3} sm={3} xs={6}> 
                                                <button className="btnesq" onClick={() => handleButtonClick('98%')} disabled={buttonClicked.btn2}>
                                                  Esquerda
                                                </button>
                                              </Col>
                                              <Col md={3} sm={3} xs={6}>
                                                <button className="btndir" onClick={() => handleButtonClick('100%')} disabled={buttonClicked.btn1}>
                                                  Direita
                                                </button>
                                              </Col>
                                              <Col md={3} sm={3} xs={0}></Col>
                                            </Row>
                                          
                                            
                                            <div className="mt-4 mb-4 mr-2 ml-2"> <p>{receivedString ? receivedString : "__%"} votaram na imagem do meu cliente!</p> 
                                            </div> 
                                            <div><p>{buttonClicked ? 'Já escolheu! Obrigado! ': 'Vote em uma das imagens...'}</p></div>
                                            <div className="border_important mt-4 width-max">
                                              <p>Já entendeu <u>quantos clientes</u> o hotel da esquerda <u>está e irá perder</u>?<br/> Se ainda acha que nos tempos de hoje uma foto amadora serve. Simplemente <b><u>irá ficar atrás dos seus concorrentes</u></b>!</p>
                                            </div>
                                          
                                            <Row className="selling-points mb-4 items-center mr-2 ml-2">
                                                <Col md={6} sm={6} xs={12} className="mt-4 text-left">
                                                    <h4>Vendas mais rápidas</h4>
                                                    <p>
                                                      Listagens com fotografias profissionais vendem mais rápido devido a um aumento de interesse.<br/> Em média <u>81 dias no caso de imóveis com valor de 400.000€</u>, como indica estudo da <a href="https://www.redfin.com/blog/professional-real-estate-photos-sell-homes-for-more/"><u>Redfin</u></a>.
                                                    </p>
                                                </Col>
                                                <Col md={6} sm={6} xs={12} className="mt-4 text-center-1">
                                                <img className="w-3-4" src="./img/Site/photoshots/hotel_photography/sell.jpg" />
                                                </Col>
                                            </Row>
                                                <hr/>
                                            <Row className=" desktop-info selling-points mb-4 items-center mr-2 ml-2">

                                                <Col md={6} sm={6} xs={12} className="mt-4 text-center-1">
                                                  <img className="w-3-4" src="./img/Site/photoshots/hotel_photography/handshake.jpg"/>
                                                  </Col>
                                                <Col md={6} sm={6} xs={12} className="mt-4 text-left">
                                                    <h4>Atraia Mais Clientes</h4>
                                                    <p>Fotografias cativantes atraiem mais clientes. Seja para <u>venda ou airbnb</u>. <br/> O que se traduz em <u>mais rentabilidade</u>, seja em <u>vendas ou aluguer</u>.</p>
                                                </Col>
                                                {/* ... (Other Selling Points) */}
                                            </Row> 
                                            {/* mobile */}
                                            <Row className=" mobile_info selling-points mb-4 items-center mr-2 ml-2">

                                              
                                                <Col md={6} sm={6} xs={12} className="mt-4 text-left">
                                                    <h4>Atraia Mais Clientes</h4>
                                                    <p>Fotografias cativantes atraiem mais clientes. Seja para <u>venda ou airbnb</u>. <br/> O que se traduz em <u>mais rentabilidade</u>, seja em <u>vendas ou aluguer</u>.</p>
                                                </Col>
                                                <Col md={6} sm={6} xs={12} className="mt-4 text-center-1">
                                                  <img className="w-3-4 " src="./img/Site/photoshots/hotel_photography/handshake.jpg"/>
                                                  </Col>
                                                {/* ... (Other Selling Points) */}
                                            </Row>
                                            <hr/>
                                            <Row>
                                              
                                              
                                              <h2 className="mb-2 mt-2 text-center">Pacotes</h2>
                                              
                                              <div className="packages-section">

                                            {packages.map(pkg => (
                                              <Col md={6} sm={6} xs={12}>
                                                     <PackageCard key={pkg.title} {...pkg} />
                                              </Col>

                                              
                                              ))}
                                            </div>
                                          
                                            </Row>
                                            

                                            {/* Your Gallery Component */}
                                            <hr></hr>
                                            <h1 className="display-4 title_mobile mb-4 mt-10 mr-2 ml-2"> Galeria com meu portfólio</h1>
                                            <div className="container gallery-section mb-4 mr-2 ml-2">
                                            <div className="container ab_block imgs_gallery ">
                                              
                                      <ResponsiveMasonry
                                        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                      >
                                        <Masonry
                                          gutter={"10px"}
                                        >
                                          {imagesArray.map((e, key) =>
                                            <>
                                              {/* <img className="shadow-2xl bg-white p-2 " key={key} src={e.img} alt={e.alt} /> */}
                                              <img className="shadow-2xl bg-white gallery_img" key={key} src={e.img} alt={e.alt} style={{width: "100%", display: "block"}} />
                                            </>
                                          )}
                                        </Masonry>
                                      </ResponsiveMasonry>
                                    </div>
                                            </div>

                                           
                                            <section className="about-me">
        <div className="about-me-container">
          <div className="about-me-image">
            <img src="./img/Site/photoshots/hotel_photography/about-me.webp" alt="Your Name" />
          </div>
          <div className="about-me-info">
            <h2>Sobre mim</h2>
            <p>
            Sou o Afonso Rufino, fotografo com 3 anos de experiência. 
  Comecei com fotografia artistica e de arquitetura paisagistica. Pouco tempo depois iniciei caminhada em video e fotografia de casamento.
  Neste momento, viso ajudar a melhorar a idêntidade visual de agentes imobiliários e listagens de imobiliários.
            </p>
            <p>
            Acredito na utilização das tecnologias e técnicas mais recentes para fornecer imagens de alta qualidade que atraem potenciais compradores e têm um verdadeiro impacto. Seja fotografia tradicional, fotos aéreas ou tours virtuais 3D, possuo as competências e a experiência necessárias para concretizar a sua visão.
            </p>
            {/* Include any certifications or achievements if relevant */}
            <p>
              Entre em contacto para que possa elevar a história das suas propriedades através de fotografia.
            </p>
            {/* Social Media Links */}
            <a href="https://www.instagram.com/afonsorufino_luxury" target="_blank" rel="noopener noreferrer" className="instagram-link mt-8">
              <FontAwesomeIcon icon={faInstagram} className="text-center" />
               
            </a>
                <p>Siga-me no instagram</p>
            {/* Add your social media links here */}
            <div className="contact-form">
                <h4>Preparado para elevar o teu nivel?</h4>
                <a classname="contact" href="https://psmeah3sa40.typeform.com/to/ybWeZfSC">Contacta-me</a>
            </div>
          </div>
        </div>
      </section>

                                        </Container>
                                    </HelmetProvider>
                                );
                            }
