import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { dataportfoliophoto, meta } from "../../content_option";
import { Link } from "react-router-dom";


function Index() {
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Photography Gallery | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"> Photography Gallery </h1>{" "}
            <hr className="t_border mb-4 ml-0 text-left" />
          </Col>
          <h3 className="absolute goback "><a href="javascript:history.back()"> Go Back </a></h3>
        </Row>
        <div className="mb-5 po_items_ho ">
          {dataportfoliophoto.map((data, i) => {
            return (
              <div key={i} className="po_item">
                <img src={data.img} alt="" />
                <div className="content">
                  <p>{data.desctiption}</p>
                  <Link to={data.link}>
                    View project
                  </Link>
                  
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </HelmetProvider>
  );
}

export default Index;
